<template>
<div>
    <FreezerList />
</div>
</template>

<script>
  import FreezerList from '../components/manage-supply/FreezerList'

  export default {
    name: 'ManageSupply',

    components: {
      FreezerList,
    },
  }
</script>
