<template>
    <div>
        <v-card max-width="600" class="mx-auto my-10">
            <v-toolbar color="blue" dark>
                <v-toolbar-title>{{ freezer.name }}</v-toolbar-title>
            </v-toolbar>
        </v-card>

<!--        <FoodFilter @filter-changed="filter_changed" />
         <Compartment v-for="fach in faecher" :key="fach.uid" :compartment="fach" :filter="filter" /> -->
    </div>
</template>

<script>
// import Compartment from './Compartment'
// import FoodFilter from './FoodFilter'

  export default {
    name: 'Suggestions',

    components: {
//      Compartment,
//      FoodFilter,
    },

    props : {
        freezer : Object,
    },

    data: function() {
      return {
          faecher : [],
          filter  : {},
      };
    },
    methods: {
        update : function() {
            this.$backendapi.call("supply/list", this.freezer.uid, response => {
                console.log("called compartment list");
                console.log(response.data);
                this.faecher = response.data;
                console.log("update list");
            }, (err) => {
                this.$store.dispatch('setError', err);
            });
        },
        filter_changed : function(filter) {
            // console.log("CHANGE");
            this.filter = filter;
        },
    },
    mounted : function() {
        this.update();
    },
  }
</script>



