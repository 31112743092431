<template>
    <div>
        <Suggestions v-for="freezer in freezerlist" :key="freezer.uid" :freezer="freezer" /> 
    </div>
</template>

<script>
import Suggestions from './Suggestions'

  export default {
    name: 'FreezerList',

    components: {
      Suggestions,
    },

    data: function() {
      return {
          freezerlist  : {},
      };
    },
    methods: {
        update : function() {
            this.$backendapi.call("freezer/list", {},  response => {
                console.log("called freezer list");
                console.log(response.data);
                this.freezerlist = response.data;
            }, (err) => {
                this.$store.dispatch('setError', err);
            });
        },
        filter_changed : function(filter) {
            // console.log("CHANGE");
            this.filter = filter;
        },
    },
    mounted : function() {
        this.update();
    },
  }
</script>



